
/**
 * Configurable register object
 */

class ConfigurableRegister {
    private _id?: number;
    private _name: string = '';
    private _newAppId: string = '';
    private _defaultSortOrder: string = '';
    
    private _tasks: { Action: string, Name: string, Divider:boolean }[] = [];
    private _filters: { Name: string, Title: string, Filter: string, Divider:boolean, Default:boolean }[] = [];

    private _isConfigured = false;

    get id() { return this._id; }
    get name() { return this._name; }
    get newAppId() { return this._newAppId; }
    get tasks() { return this._tasks; }
    get filters() { return this._filters; }
    get isConfigured() { return this._isConfigured; }
    get defaultSortOrder() { return this._defaultSortOrder; }

    constructor() {
        try {
            const searchParams = new URLSearchParams(window.location.search);
            const id = searchParams.get('Register_ID');
            if (id && window.__navBarData.register) {
                this._id = +id;
                this._isConfigured = true
                this._newAppId = window.__navBarData.register.newAppId ?? '';
                this._defaultSortOrder = window.__navBarData.register.defaultSortOrder ?? '';
                this._name = window.__navBarData.register.name ?? '';
                this._tasks = window.__navBarData.register.tasks?JSON.parse(window.__navBarData.register.tasks ?? '[]'):null;
                this._filters = window.__navBarData.register.filters?JSON.parse(window.__navBarData.register.filters ?? '[]'):null;
            }

        } catch (ex) {
            console.warn(ex);
        }
    }
}

const configurableRegister = new ConfigurableRegister();

export default configurableRegister;